@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Lora', serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.button_primary {
  @apply
  bg-red text-white
  hover:bg-lightred hover:text-gray_bg 
  py-3 px-6
  md:py-4 md:px-8
  rounded-lg
  md:text-lg
  transition-all duration-300 ease-in-out;
}

.title {
  font-family: 'Cinzel', serif;
  font-weight: 500;
  @apply
  md:text-4xl text-3xl 
  ml-3 md:ml-0
  hover:text-lightred
  transition-all duration-500 ease-in-out;
}

.link_primary {
  @apply
  hover:text-red hover:font-normal hover:underline
  transition-all duration-300 ease-in-out;
}