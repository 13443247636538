.slide:not(.selected) img{
    opacity: 0.2;
    flex-shrink: 0;
    transition: opacity 1s;
}

.slide.selected img {
    opacity: 1;
    transition: opacity 1s;
    border-width: 8px;
    border-color: white;
}

.carousel-root {
    width: 75vh;
}

.slider.animated {
    align-items: center;
    width: 100%;
}

.slide:not(.selected) {
    border-width: 4px;
    border-color: white;
}

.slide.selected {
    border-width: 6px;
    border-color: white;
}